module.exports = {
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteTitle: "File Bankruptcy for Free | TurboTax for Bankruptcy | Upsolve", // Site title.
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteDescription: "Upsolve is America's #1 bankruptcy tool. Harvard-funded nonprofit featured 4x in Forbes.", // Website description used for RSS feeds/meta description tag.
  siteKeywords:
    "how to file for bankruptcy, filing for bankruptcy, bankruptcy forms, bankruptcy attorney, bankruptcy lawyer, chapter 7, chapter 13, free bankruptcy attorney, means test, nonprofit, pro bono, bankruptcy court, trustee, erase debt, debt consolidation",
  siteUrl: "https://upsolve.org", // Domain of your website without pathPrefix.
  siteRss: "/rss.xml", // Path to the RSS file.
  copyright: `Copyright © ${new Date().getFullYear()}`, // Copyright string for the footer of the website and RSS feed.
  themeColor: "#3C5DFF", // Used for setting manifest and progress theme colors.
  backgroundColor: "#FFFFFF", // Used for setting manifest background color.
  userTwitter: "@upsolvebk",
};
